
<template>
    <header id="header" class="container-fluid fixed-top">
        <div class="navbar  navbar-expand-lg navbar-light">
            <!-- <button type="button" class="navbar-toggler bg-primary text-white  mx-3" aria-label="UP button"
                title="UP button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" id="navbarButton">
                <span class="navbar-toggler-icon text-white"></span>
            </button> -->
            <ul class="btnMobile">
                <router-link to="/" class="navbar-brand text-uppercase" exact>
                    <img src="../assets/img/newLogo.jpg" id="logo" class="img-fluid pt-1 mx-5 "
                        alt="logo-mascareignes-1789">
                </router-link>
                <li type="button" id="iosbtn" name="Mobil button" role="button" aria-label="Center Align"
                    class="tg-list-item  mx-3">
                    <input id="cb2" type="checkbox" class="tgl tgl-ios" @click="toggleNav()">
                    <label for="cb2" aria-hidden="true" class="tgl-btn"></label>
                </li>
            </ul>
            <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                <ul class="menu">
                    <li class="nav-item">
                        <RouterLink to="/" aria-label="link-Accueil" exact>
                            Accueil
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/about" aria-label="link-apropos" exact>A Propos</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/media" aria-label="link-media" exact>Media</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/contact" aria-label="link-contact" exact>Contact</RouterLink>
                    </li>
                    <li class="nav-item" v-if="isUserConnected">
                         <RouterLink  to="/userPage" class="btn btn-danger text-white">User Profile</RouterLink>
                        </li>
                    <li class="dropdown">
                        <a class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Nos Actions
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li class="dropdown-item">
                                <router-link to="/admindPage" class="dropdown-item" exact>
                                    Profile</router-link>
                            </li>
                            <li>
                                <router-link to="nosService" class="dropdown-item" exact>Nos Services</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/notre-methode-de-travail" exact>Notre méthode de
                                    travail</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'realisation' }" class="dropdown-item">Nos
                                    réalisation</router-link>
                            </li>
                            <li>
                                <a href="/courses/" class="dropdown-item">
                                    Nos Formations</a>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
            <aside :class="{ active: active}">
                <ul>
                    <li class="nav-item">
                        <RouterLink to="/" aria-label="link-Accueil" exact>
                            Accueil
                        </RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/about" aria-label="link-apropos" exact>A Propos</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/media" aria-label="link-media" exact>Media</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink to="/contact" aria-label="link-contact" exact>Contact</RouterLink>
                    </li>
                    <li class="dropdown">
                        <a class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Nos Actions
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li class="dropdown-item">
                                <router-link to="/admindPage"  exact>
                                    Profile</router-link>
                            </li>
                            <li class="dropdown-item">
                                 <router-link to="nosService" exact>Nos Services</router-link>
                            </li>
                            <li class="dropdown-item">
                                <router-link  to="/notre-methode-de-travail" exact>Notre méthode de
                                    travail</router-link>
                            </li>
                            <li class="dropdown-item">
                                <router-link :to="{ name: 'realisation' }" >Nos
                                    réalisation</router-link>
                            </li>
                            <li class="dropdown-item">
                               <a href="/courses/" >
                                    Nos Formations</a>
                            </li>

                        </ul>
                    </li>
                </ul>
            </aside>
        </div>
    </header>
</template>
<script>

export default {
    data(){
     return{
       active:false,
       isUserConnected: false,
      
     }
  },
    components: {

    },

    created() {
    this.checkUserConnection();
  },
  methods: {
    checkUserConnection() {
      // Check if the user is connected by looking in local storage
      const user = localStorage.getItem('user');
      this.isUserConnected = !!user; // Set to true if user exists
      },
   
    toggleNav(){
      this.active = !this.active
    },
    close(){
       this.active = !this.active  
    },
    },
   
}
</script>

<style scoped>
#logo {
    width: 70px;
    height: 70px;
}
 aside{
  display: none;
  width: 200px;
  background :rgb(81, 112, 255);
  height:100vh;
  color:#e8eae9;
  }
  #cb2{
    display:none;
  }

@media (max-width:768px) {
    .mobileContainer {
        margin-top: -68px;
    }

    .btnMobile {
        display: block;
        display: flex;
        margin: 0
    }

    .btnMobile>li {
        display: inline-block;
        list-style: none
    }

    .btnMobile .tg-list-item {
        display: inline-block;
        margin-left: 0px;
        margin-top: 11px;
    }
   
  aside.active{
    display:block;
    position:relative;
    z-index:4;
    margin-top:2px;
}
 aside a{
    display :block;
    padding :10px 5px;
    color: #fff;
    border-bottom :1px solid #bbb;
 }
 .dropdown-item > a{
    color:#000;
    text-decoration: none;
 }

    .tgl {
        display: none
    }

    .tgl,
    .tgl *,
    .tgl :after,
    .tgl :before,
    .tgl+.tgl-btn,
    .tgl:after,
    .tgl:before {
        box-sizing: border-box
    }

    .tgl ::selection,
    .tgl :after::selection,
    .tgl :before::selection,
    .tgl+.tgl-btn::selection,
    .tgl::selection,
    .tgl:after::selection,
    .tgl:before::selection {
        background: 0 0
    }

    .tgl+.tgl-btn {
        outline: 0;
        display: block;
        width: 4em;
        height: 2em;
        position: relative;
        cursor: pointer;
        user-select: none
    }

    .tgl+.tgl-btn:after,
    .tgl+.tgl-btn:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%
    }

    .tgl+.tgl-btn:after {
        left: 0
    }

    .tgl+.tgl-btn:before {
        display: none
    }

    .tgl:checked+.tgl-btn:after {
        left: 50%
    }

    .tgl-light+.tgl-btn {
        background: #f0f0f0;
        border-radius: 2em;
        padding: 2px;
        transition: all .4s ease
    }

    .tgl-light+.tgl-btn:after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease
    }

    .tgl-light:checked+.tgl-btn {
        background: #9fd6ae
    }

    .tgl-ios+.tgl-btn {
        background: #fbfbfb;
        border-radius: 2em;
        padding: 2px;
        transition: all .4s ease;
        border: 1px solid #e8eae9
    }

    .tgl-ios+.tgl-btn:after {
        border-radius: 2em;
        background: #fbfbfb;
        transition: left .3s cubic-bezier(.175, .885, .32, 1.275), padding .3s ease, margin .3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 0 rgba(0, 0, 0, .08)
    }

    .tgl-ios+.tgl-btn:hover:after {
        will-change: padding
    }

    .tgl-ios+.tgl-btn:active {
        box-shadow: inset 0 0 0 2em #e8eae9
    }

    .tgl-ios+.tgl-btn:active:after {
        padding-right: .8em
    }

    .tgl-ios:checked+.tgl-btn {
        background: #8e21f3
    }

    .tgl-ios:checked+.tgl-btn:active {
        box-shadow: none
    }

    .tgl-ios:checked+.tgl-btn:active:after {
        margin-left: -.8em
    }

    .tgl-flat+.tgl-btn {
        padding: 2px;
        transition: all .2s ease;
        background: #fff;
        border: 4px solid #f2f2f2;
        border-radius: 2em
    }

    .tgl-flat+.tgl-btn:after {
        transition: all .2s ease;
        background: #f2f2f2;
        content: "";
        border-radius: 1em
    }

    .tgl-flat:checked+.tgl-btn {
        border: 4px solid #7fc6a6
    }

    .tgl-flat:checked+.tgl-btn:after {
        left: 50%;
        background: #7fc6a6
    }

    .tgl-flip+.tgl-btn {
        padding: 2px;
        transition: all .2s ease;
        font-family: sans-serif;
        perspective: 100px
    }

    .tgl-flip+.tgl-btn:after,
    .tgl-flip+.tgl-btn:before {
        display: inline-block;
        transition: all .4s ease;
        width: 100%;
        text-align: center;
        position: absolute;
        line-height: 2em;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        border-radius: 4px
    }

    .tgl-flip+.tgl-btn:after {
        content: attr(data-tg-on);
        background: #02c66f;
        transform: rotateY(-180deg)
    }

    .tgl-flip+.tgl-btn:before {
        background: #ff3a19;
        content: attr(data-tg-off)
    }

    .tgl-flip+.tgl-btn:active:before {
        transform: rotateY(-20deg)
    }

    .tgl-flip:checked+.tgl-btn:before {
        transform: rotateY(180deg)
    }

    .tgl-flip:checked+.tgl-btn:after {
        transform: rotateY(0);
        left: 0;
        background: #7fc6a6
    }

    .tgl-flip:checked+.tgl-btn:active:after {
        transform: rotateY(20deg)
    }
}
</style>